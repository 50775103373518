/* eslint-disable @next/next/no-img-element */
import { HeartIcon } from '@heroicons/react/24/outline';
import CustomLeftArrow from "components/controls/carousel/CustomLeftArrow";
import CustomRightArrow from "components/controls/carousel/CustomRightArrow";
import Image from 'next/image';
import { useCallback, useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import { DeviceTypes, PricingTypes, RatePeriods, RateTypes } from "utilities/constants";
import isEmpty from "utilities/isEmpty";
import localiseCurrency from "utilities/localiseCurrency";

//This is a fix for janky animations in scroll with react multi carousel
//see https://github.com/akiran/react-slick/issues/1240
let firstClientX, clientX;
const preventTouch = e => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;

        return false;
    }
};
const touchStart = e => {
    firstClientX = e.touches[0].clientX;
};

export default function SpaceCarousel(props) {
    const { data, disable, deviceType, ...rest } = props;

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [clientSideReady, setClientSideReady] = useState(false);

    const numberOfImagesToPreload = 3;
    const numberOfDotsPastIndex = 2;

    let containerRef = useRef();

    useEffect(() => {
        //We do this to prevent client errors
        setIsTouchDevice(getIsTouchDevice());

        if (containerRef.current) {
            containerRef.current.addEventListener("touchstart", touchStart);
            containerRef.current.addEventListener("touchmove", preventTouch, {
                passive: false
            });
        }

        if (!clientSideReady) {
            setClientSideReady(true);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("touchstart", touchStart);
                containerRef.current.removeEventListener("touchmove", preventTouch, {
                    passive: false
                });
            }
        };
    }, []);

    const getIsTouchDevice = () => {
        return (typeof window != "undefined" && (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0)));
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const getPeriodText = (ratePeriodId) => {
        if (ratePeriodId != null) {
            switch (ratePeriodId) {
                case RatePeriods.PER_HOUR:
                    return "per hour";
                case RatePeriods.PER_SESSION:
                    return "per session";
                case RatePeriods.PER_MORNING:
                    return "per morning";
                case RatePeriods.PER_AFTERNOON:
                    return "per afternoon";
                case RatePeriods.PER_EVENING:
                    return "per evening";
                case RatePeriods.PER_DAY:
                    return "per day";
            }
        }

        return null;
    }

    const getRateTypeText = (rateTypeId, ratePeriodId) => {
        if (rateTypeId != null) {
            switch (rateTypeId) {
                case RateTypes.HIREFEE:
                    return "hire fee";
                case RateTypes.MINIMUM_SPEND:
                    return "min. spend";
                case RateTypes.PACKAGE_PER_PERSON:
                    return "per person";
                case RateTypes.HIRE_FEE_AND_MINIMUM_SPEND:
                    return "hire fee + min. spend";
                case RateTypes.HIRE_FEE_AND_PACKAGE_PER_PERSON:
                    return "hire fee + per person";
            }
        }

        return null;
    }

    const CustomDot = ({ onMove, index, onClick, active }) => {
        // onMove means if dragging or swiping in progress.
        // active is provided by this lib for checking if the item is active or not.

        if (index > (currentSlideIndex + numberOfDotsPastIndex))
            return null;

        if (index < currentSlideIndex && index < (currentSlideIndex - numberOfDotsPastIndex))
            return null;

        return (

            <li
                className={`space-carousel-dot-item ${active ? "space-carousel-dot-item-active" : ""}`}
            >
                <button aria-label="Go to slide 1"></button>
            </li>
        );
    };

    // // sm: '480px',
    // // md: '768px',
    // // lg: '1030px',
    // // xl: '1440px',
    // // xxl: '1800px',
    const getPhoto = useCallback((photo, index) => {
        return <picture>
            <source media="(min-width: 1800px)" width="447px" height={"314px"} className='w-full' srcSet={`${photo.url}?width=447&height=314&rmode=crop&scale=both&quality=100`} loading={index === 0 ? "eager" : "lazy"} />
            <source media="(min-width: 1440px)" width="430px" height={"303px"} className='w-full' srcSet={`${photo.url}?width=430&height=303&rmode=crop&scale=both&quality=100`} loading={index === 0 ? "eager" : "lazy"} />
            <source media="(min-width: 1030px)" width="343px" height={"241px"} className='w-full' srcSet={`${photo.url}?width=343&height=241&rmode=crop&scale=both&quality=100`} loading={index === 0 ? "eager" : "lazy"} />
            <source media="(min-width: 768px)" width="322px" height={"227px"} className='w-full' srcSet={`${photo.url}?width=322&height=227&rmode=crop&scale=both&quality=100`} loading={index === 0 ? "eager" : "lazy"} />
            <source media="(min-width: 480px)" width="360px" height={"253px"} className='w-full' srcSet={`${photo.url}?width=360&height=253&rmode=crop&scale=both&quality=100`} loading={index === 0 ? "eager" : "lazy"} />
            {/* 415 x 823 */}
            <source media="(min-width: 415px)" width="383px" height={"270px"} className='w-full' srcSet={`${photo.url}?width=383&height=270&rmode=crop&scale=both&quality=100`} loading={index === 0 ? "eager" : "lazy"} />
            {/* 414 and below */}
            <Image src={`${photo.url}?width=382&height=269&rmode=crop&scale=both`} quality={80} width={382} height={269} className='w-full' alt='Image' loading={index === 0 ? "eager" : "lazy"} priority={index === 0} />
        </picture>
    }, [])

    return (<figure className={'not-prose  aspect-[500/350] '} ref={containerRef}>
        <div className='space-carousel'>{!disable && clientSideReady && !isEmpty(data.photos) && data.photos.length > 0 &&
            <Carousel
                afterChange={(previousSlide, { currentSlide, onMove }) => {
                    setCurrentSlideIndex(currentSlide);
                }}
                swipeable={isTouchDevice}
                draggable={false}
                responsive={responsive}
                ssr
                itemClass="space-carousel-item"
                deviceType={deviceType ?? DeviceTypes.MOBILE}
                arrows={!isTouchDevice}
                partialVisible={false}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                showDots={true}
                customDot={<CustomDot />}
                dotListClass="space-carousel-dot-list">
                {data.photos.map((photo, index) => <div key={`space_image_${index}`}>
                    {(index - numberOfImagesToPreload) <= currentSlideIndex && getPhoto(photo, index)}
                </div>)}
            </Carousel>
        }
            {disable || !clientSideReady && <div className='space-carousel-item'>
                {!isEmpty(data.photos) && data.photos.length > 0 && <div>{getPhoto(data.photos[0], 0)}</div>}
            </div>}</div>
        <button className='btn btn-sm btn-circle !right-2 !top-[10px] !px-[5px] !py-0  !absolute' aria-label="Add to favourites">
            <HeartIcon
                strokeWidth={2}
                className="h-5 w-5 text-base-content"
            />
        </button>
        {!isEmpty(data.minimumAmount) && <div
            data-theme="light"
            className={`!right-2 !bottom-[76px] !py-0 !pb-1 !px-2  !absolute bg-black/80 text-white  text-center text-xs rounded-md  bg-opacity-90 leading-1 font-light`}>
            <div>
                from <span className="text-lg font-bold" suppressHydrationWarning={true}>{localiseCurrency(data.minimumAmount, 0)}</span>
            </div>
            {!isEmpty(data.pricingTypeId) && (data.pricingTypeId === PricingTypes.HIREFEEONLY || data.pricingTypeId === PricingTypes.CUSTOMPRICING) && !isEmpty(data.minimumRatePeriodId) && !isEmpty(data.minimumRateTypeId) && <div className='text-xs'>{getRateTypeText(data.minimumRateTypeId)} / {getPeriodText(data.minimumRatePeriodId)}</div>}
            {!isEmpty(data.pricingTypeId) && (data.pricingTypeId === PricingTypes.PACKAGESONLY) && <div className='text-xs'>per event package</div>}
        </div>}

    </figure>);
}