import { MapPinIcon, StarIcon } from "@heroicons/react/24/solid";
import { Chair } from "components/controls/svg/Chair";
import { Person } from "components/controls/svg/Person";
import { SuperVenue } from "components/controls/svg/SuperVenue";
import isEmpty from "utilities/isEmpty";

export default function SpaceDescription(props) {
    const { data } = props;

    return <>
        <div className="!mt-0 !mb-0 text-base-content text-sm  flex justify-between">
            <div className="truncate text-ellipsis font-semibold">
                {data?.spaceName}
            </div>
            <div className="flex flex-shrink-0 pl-1">
                {!isEmpty(data?.maxStanding) &&
                    <>
                        <Chair className="h-[14px] w-[14px] mt-[3px] text-base-content" />
                        {data?.maxStanding}
                    </>}
                {!isEmpty(data?.maxSeated) &&
                    <>
                        <Person className=" h-[14px] w-[14px] mt-[3px] text-base-content" />
                        {data?.maxSeated}
                    </>}
            </div>
        </div>
        <div className="text-sm flex justify-between">
            <div className="truncate text-ellipsis">
                {data?.venueName}
            </div>
            {!isEmpty(data?.averageMeanRating) && data?.averageMeanRating > 0 &&
                <div className="flex flex-shrink-0 pl-1 ">
                    <StarIcon
                        strokeWidth={2}
                        className="h-[14px] w-[14px] mt-[3px] text-primary"
                    />
                    {data?.averageMeanRating.toFixed(1)}
                </div>}
        </div>
        <div className="text-sm flex justify-between">
            <div className="truncate text-ellipsis">
                <div className="text-xs  flex row-auto items-center">
                    <div>
                        <MapPinIcon strokeWidth={0} className="h-4 w-4 text-primary" />
                    </div>
                    <div>&nbsp;{data?.suburbAndCity}</div>
                </div>
            </div>
            {!isEmpty(data?.isSuperVenue) && data?.isSuperVenue &&
                <div className="flex flex-shrink-0 pl-1 text-xs">
                    <SuperVenue
                        strokeWidth={2}
                        className="h-4 w-4   text-primary"
                    />
                    #Supervenue
                </div>
            }
        </div>
    </>
}